































































import Table from "@monorepo/uikit/src/components/common/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/generatedReportsView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/generatedReportsView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as GeneratedReportsView } from "@monorepo/reports/src/store/modules/generatedReportsView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/reports/src/views/GeneratedReportsView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { convertApiItemToUi } from "@monorepo/reports/src/views/GeneratedReportsView/utils/convertApiItemToUi";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

const SECTION_NAME = "generatedReportsView";

export default defineComponent({
  name: "generatedReportsView",
  components: {
    TableSettings,
    ExportButton,
    SortPanelContainer,
    ToggleTableCellsContainer,
    InfiniteScroll,
    Table,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    OpenCardButton,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      isOpenFilters: false,
      headerCheckboxValue,
      tableHeaders,
      convertApiItemToUi,
    };
  },
  computed: {
    ...mapGetters(SECTION_NAME, ["cells", "openedId", "isSelectAll", "infiniteId", "data", "totalLength", "isTableLoading"]),
    ...mapGetters("auth", ["user"]),
    headers(): typeof tableHeaders {
      return this.isCanDelete ? tableHeaders : tableHeaders.filter((item) => item.value !== headerCheckboxValue);
    },
    isCanDelete(): boolean {
      return isAuthorityExist(this.user, authorities.REPORT_GENERATED_DELETE);
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.REPORT_GENERATED_EXPORT);
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId", "deleteElement", "downloadElement", "refreshScroll"]),
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
    deleteElementCb(id: number | string) {
      return async () => {
        const isDeleted = await this.deleteElement(id);
        if (isDeleted) {
          this.refreshScroll();
        }
      };
    },
    openDeleteModal(event: Record<string, unknown>) {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.deleteElementCb(event.id as string),
          title: `Удалить ОИК «${event.name || ""}»?`,
        })
      );
    },
  },
  setup(props, { root }) {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, GeneratedReportsView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);

    return {
      sectionName,
    };
  },
});
