import { IReportElement } from "@monorepo/reports/src/views/GeneratedReportsView/types/reportElement";
import moment from "moment";

const convertTime = (date: string): string => {
  return date ? moment(date).format("YYYY-MM-DD") : "";
};

export const convertApiItemToUi = (item: IReportElement): IReportElement => ({
  ...item,
  createDate: convertTime(item.createDate),
});
