import { Module } from "vuex";
import { IRootState } from "@monorepo/reports/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/reports/src/views/GeneratedReportsView/utils/convertFiltersToApi";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi } from "@monorepo/utils/src/api/convertFiltersToApi";
import { IReportElement } from "@monorepo/reports/src/views/GeneratedReportsView/types/reportElement";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { actions } from "@monorepo/utils/src/store/modules/actions";
import { getters } from "@monorepo/utils/src/store/modules/getters";
import { mutations } from "@monorepo/utils/src/store/modules/mutations";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import axios from "axios";

interface ITableState {
  filters: Partial<ITableFiltersObject>;
  totalLength: number;
  section?: Sections;
  infiniteId: string | null;
  sectionCells: ISection;
  isOpenFilters: boolean;
  data: IReportElement[];
  searchTemplates: ISearchTemplate[];
  cells: string[];
  libraries: {
    oiks: string[];
  };
  isTableLoading: boolean;
}

const defaultFilters = (): Partial<ITableFiltersObject> => ({
  fieldFilters: {},
  sort: {
    createDate: SORT_TYPE.DESC,
  },
  initMessagesLength: 50,
  isSelectAll: false,
  selectedIds: {},
});

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    sectionCells: {} as ISection,
    infiniteId: new Date().toString(),
    isOpenFilters: false,
    totalLength: 0,
    section: Sections.GENERATED_RECEIPTS,
    cells: [],
    searchTemplates: [],
    data: [],
    libraries: {
      oiks: [],
    },
    isTableLoading: false,
  }),
  mutations: {
    ...mutations,
    addOiksLib(state: ITableState, payload: string[]) {
      state.libraries.oiks = payload || [];
    },
    addData(
      state: ITableState,
      payload: {
        data: IReportElement[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    clearEventList(state: ITableState) {
      state.data = [];
      state.totalLength = 0;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...actions,
    clearEventList({ commit }) {
      commit("clearEventList");
    },
    async deleteElement(info, id: number | string) {
      try {
        await axios.delete(getFullPath(`${QUERY_PATH.GET_GENERATED_REPORT}/${id}`));
        return true;
      } catch (e) {
        return false;
      }
    },
    async downloadElement({ dispatch }, fileUuid: number | string) {
      dispatch("app/openNewWindow", getFullPath(`${QUERY_PATH.GET_FILE_FROM_FILE_STORAGE}?uuid=${fileUuid}&origin=true`), { root: true });
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters as ITableFiltersObject, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IReportElement[]>(QUERY_PATH.GET_GENERATED_REPORT, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || data?.length || 0 });
        return { data: state.data || [], totalLength: totalLength || data?.length || 0 };
      }
      return { data: null, error };
    },
    async getExportData() {
      return;
    },
  },
  getters: {
    ...getters,
    oiksLibrary(state: ITableState) {
      return state.libraries.oiks;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    cells() {
      return [];
    },
  },
};
